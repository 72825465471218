<template>
  <div class="contents register userInfo video">
    <section class="box one">
      <h2>비디오 학습 관리</h2>
      <div class="inputBox">
        <!-- <div class="flex">
          <p >
            파트
            <span>*</span>
          </p>
          <input type="text" v-model="part" />
        </div> -->

        <!-- <div class="flex">
          <p >
            최대개수
            <span>*</span>
          </p>
          <input type="number" v-model="maxResults" />
        </div>
        <div class="flex">
          <p >
            페이지토큰
            <span>*</span>
          </p>
          <input type="text" v-model="pageToken" />
        </div>
        <div class="flex">
          <p >
            지역코드
            <span>*</span>
          </p>
          <input type="text" v-model="regionCode" />
        </div> -->

        <div class="flex">
          <p>
            제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>

        <div class="flex">
          <p>
            카테고리
            <span>*</span>
          </p>
          <el-checkbox-group v-model="categories">
            <el-checkbox :label="'READ'">읽기</el-checkbox>
            <el-checkbox :label="'WRITE'">쓰기</el-checkbox>
            <el-checkbox :label="'SPEAK'">말하기</el-checkbox>
            <el-checkbox :label="'LISTEN'">듣기</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="flex">
          <p>
            토픽
            <span>*</span>
          </p>
          <el-select v-model="topic">
            <el-option label="일상" value="일상"> </el-option>
            <el-option label="비즈니스" value="비즈니스"> </el-option>
            <el-option label="감정" value="감정"> </el-option>
            <el-option label="K-POP" value="K-POP"> </el-option>
            <el-option label="스포츠" value="스포츠"> </el-option>
            <el-option label="음식" value="음식"> </el-option>
          </el-select>
        </div>

        <div class="flex">
          <p>
            레벨
            <span>*</span>
          </p>
          <el-select v-model="level">
            <el-option label="Level 1" value="1"> </el-option>
            <el-option label="Level 2" value="2"> </el-option>
            <el-option label="Level 3" value="3"> </el-option>
            <el-option label="Level 4" value="4"> </el-option>
            <el-option label="Level 5" value="5"> </el-option>
          </el-select>
        </div>

        <div class="flex">
          <p>
            유튜브 영상 ID
            <span>*</span>
          </p>
          <input type="text" v-model="id" />
        </div>
        <div class="flex">
          <p>영상 시작 시간</p>
          <input type="text" v-model="startTime" placeholder="HH:MM:SS" />
        </div>
        <div class="flex">
          <p>
            썸네일
            <span>*</span>
          </p>
          <el-select v-model="thumbnailNo">
            <el-option label="썸네일" value="0"> </el-option>
            <el-option label="클립1" value="1"> </el-option>
            <el-option label="클립2" value="2"> </el-option>
            <el-option label="클립3" value="3"> </el-option>
          </el-select>
          <img
            class="thumbnail"
            v-if="id && thumbnailNo"
            :src="
              'https://img.youtube.com/vi/' + id + '/' + thumbnailNo + '.jpg'
            "
            alt="thumbnail"
          />
        </div>

        <div class="flex">
          <p>영상 끝 시간</p>
          <input type="text" v-model="endTime" placeholder="HH:MM:SS" />
        </div>
        <!-- <div class="flex">
          <p >특정 반복 시작 시간</p>
          <input
            type="text"
            v-model="specialRepeatStart"
            placeholder="HH:MM:SS"
          />
        </div>
        <div class="flex">
          <p >특정 반복 끝 시간</p>
          <input
            type="text"
            v-model="specialRepeatEnd"
            placeholder="HH:MM:SS"
          />
        </div> -->
        <!-- <div class="flex double">
          <p >반복 이후 시작 시간</p>
          <input type="text" v-model="afterRepeatTime" />
        </div> -->

        <!-- <div class="flex" v-if="editMode">
          <p >힌트 텍스트</p>
          <input type="text" v-model="hintText" disabled />
        </div>
        <div class="flex" v-if="editMode">
          <p >히든 텍스트</p>
          <input type="text" v-model="hiddenText" disabled />
        </div>
        <div class="flex" v-if="editMode">
          <p >앞 문장</p>
          <input type="text" v-model="frontText" disabled />
        </div>
        <div class="flex" v-if="editMode">
          <p >뒷 문장</p>
          <input type="text" v-model="backText" disabled />
        </div> -->
        <div class="flex double">
          <p>자막 목록</p>
          <button class="basic" @click="addSubtitle">+ 추가</button>
        </div>

        <div style="padding-bottom: 100px">
          <div
            v-for="(subtitle, index) in subtitles"
            :key="index"
            class="subtitle-item inputBox"
          >
            <div class="flex double checkWrap">
              <div class="flex">
                <div class="flex">
                  <el-checkbox
                    @change="highlightCheck(index)"
                    v-model="subtitle.isHighlight"
                  ></el-checkbox>
                  <p>강조</p>
                </div>
                <div class="flex" v-if="categories.includes('WRITE')">
                  <el-checkbox
                    @change="quizCheck(index)"
                    v-model="subtitle.isQuiz"
                  ></el-checkbox>
                  <p>퀴즈</p>
                </div>
              </div>
              <button class="line small" @click="removeSubtitle(index)">
                삭제
              </button>
            </div>

            <div class="flex">
              <p>시작 시간</p>
              <input
                type="text"
                v-model="subtitle.start"
                placeholder="HH:MM:SS"
              />
            </div>
            <div class="flex">
              <p>끝 시간</p>
              <input
                type="text"
                v-model="subtitle.end"
                placeholder="HH:MM:SS"
              />
            </div>
            <div class="flex">
              <p>영어 자막</p>
              <input type="text" v-model="subtitle.en" />
            </div>
            <div class="flex">
              <p>한글 자막</p>
              <input type="text" v-model="subtitle.ko" />
              <!-- @input="updateInput('ko', index, $event)" -->
            </div>

            <div class="flex" v-if="subtitle.isHighlight">
              <p>강조할 영어 단어</p>
              <input type="text" v-model="subtitle.highlightEn" />
            </div>
            <div class="flex" v-if="subtitle.isHighlight">
              <p>강조할 한글 단어</p>
              <input
                type="text"
                v-model="subtitle.highlightKo"
                @input="updateInput('highlightKo', index, $event)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </section>

    <div class="buttonWrap">
      <router-link to="/admin/video/manage">
        <span class="material-icons-round"> keyboard_backspace </span
        >목록</router-link
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { createVideo, fetchVideo, updateVideo, removeVideo } from "@/api/admin";
export default {
  data() {
    return {
      editMode: false,
      moment: moment,
      part: "",
      id: "",
      maxResults: "",
      pageToken: "",
      regionCode: "",
      thumbnailUrl: "",
      thumbnailNo: "",
      title: "",
      hintText: "",
      hiddenText: "",
      frontText: "",
      backText: "",
      startTime: "",
      endTime: "",
      specialRepeatStart: "",
      specialRepeatEnd: "",
      afterRepeatTime: "",
      subtitles: [],
      updatedAt: "",
      createdAt: "",
      modalVisible: false,

      level: null,
      topic: null,
      categories: [],
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "4");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.videoId = this.$route.query.id;
      this.getVideoDetail();
    }
  },
  methods: {
    getVideoDetail() {
      fetchVideo(this.videoId).then((res) => {
        console.log("fetchVideo", res);
        if (res.data.status == 200) {
          this.part = res.data.data.part;
          this.id = res.data.data.id;
          this.maxResults = res.data.data.maxResults;
          this.pageToken = res.data.data.pageToken;
          this.regionCode = res.data.data.regionCode;
          this.thumbnailUrl = res.data.data.thumbnailUrl;
          this.thumbnailNo =
            res.data.data.thumbnailUrl.match(/(\d+)(?=\.jpg)/)[1];
          this.title = res.data.data.title;
          this.level = String(res.data.data.level);
          this.hintText = res.data.data.hintText;
          this.topic = res.data.data.topic;
          this.hiddenText = res.data.data.hiddenText;
          this.frontText = res.data.data.frontText;
          this.backText = res.data.data.backText;
          this.categories = res.data.data.categories;
          this.subtitles = res.data.data.subtitles.map((item) => ({
            ...item,
            start: this.secondsToTime(item.start),
            end: this.secondsToTime(item.end),
          }));

          // this.specialRepeatStart = res.data.data.specialRepeatStart;
          // this.specialRepeatEnd = res.data.data.specialRepeatEnd;

          this.startTime = this.secondsToTime(res.data.data.startTime);
          this.endTime = this.secondsToTime(res.data.data.endTime);
          // this.afterRepeatTime = res.data.data.afterRepeatTime;
          // this.subtitles = res.data.data.subtitles.map((subtitle) => ({
          //   start: subtitle.start,
          //   end: subtitle.end,
          //   en: subtitle.en,
          //   ko: subtitle.ko,
          //   highlightEn: subtitle.highlightEn,
          //   highlightKo: subtitle.highlightKo
          // }));
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },

    handleDelete() {
      let result = confirm("삭제 하시겠습니까?");
      if (!result) {
        return;
      }
      removeVideo(this.videoId).then((res) => {
        if (res.data.status == 200) {
          alert("비디오학습 정보가 삭제 되었습니다.");
          this.$router.replace("/admin/video/manage");
        }
      });
    },
    addSubtitle() {
      this.subtitles.push({
        start: "",
        end: "",
        en: "",
        ko: "",
        highlightEn: "",
        highlightKo: "",
        isHighlight: false,
        isQuiz: false,
      });
    },

    // 🔹 특정 자막 삭제
    removeSubtitle(index) {
      this.subtitles.splice(index, 1);
    },

    // 🔹 highlightEn을 배열로 변환
    updateHighlightEn(index) {
      this.subtitles[index].highlightEn = this.subtitles[index].highlightEn
        .split(",")
        .map((word) => word.trim());
    },

    // 🔹 highlightKo를 배열로 변환
    updateHighlightKo(index) {
      this.subtitles[index].highlightKo = this.subtitles[index].highlightKo
        .split(",")
        .map((word) => word.trim());
    },
    submit() {
      // if (this.part == "") {
      //   return alert("파트를 입력해 주세요.");
      if (this.title == "") {
        return alert("제목을 입력해 주세요.");
      } else if (this.level == null) {
        return alert("레벨을 선택해 주세요.");
      } else if (this.categories.length == 0) {
        return alert("카테고리를 선택해 주세요.");
      } else if (this.id == "") {
        return alert("id를 입력해 주세요.");
        // } else if (this.maxResults == "") {
        //   return alert("최대개수를 입력해 주세요.");
        // } else if (this.pageToken == "") {
        //   return alert("페이지토큰을 입력해 주세요.");
        // } else if (this.regionCode == "") {
        //   return alert("지역코드를 입력해 주세요.");
      } else if (this.thumbnailNo == "") {
        return alert("썸네일을 선택해 입력해 주세요.");
        // } else if (this.hintText == "") {
        //   return alert("힌트 텍스트를 입력해 주세요.");
        // } else if (this.hiddenText == "") {
        //   return alert("히든 텍스트를 입력해 주세요.");
      } else if (this.subtitles.length == 0) {
        return alert("자막 목록을 추가해 주세요.");
      } else if (this.categories.includes("WRITE")) {
        const hasAtLeastOneTrue = this.subtitles.some((item) => item.isQuiz);
        if (!hasAtLeastOneTrue) {
          return alert("자막의 퀴즈 여부를 선택해 주세요.");
        }
      }
      // else if (this.startTime == "") {
      //   return alert("영상 시작 시간(초)을 입력해 주세요.");

      // } else if (this.endTime == "") {
      //   return alert("영상 끝 시간(초)을 입력해 주세요.");

      // } else if (this.specialRepeatStart == "") {
      //   return alert("영상 특정 반복 시작 시간(초)을 입력해 주세요.");

      // } else if (this.specialRepeatEnd == "") {
      //   return alert("영상 특정 반복 끝 시간(초)을 입력해 주세요.");
      // } else if (this.afterRepeatTime == "") {
      //   return alert("반복 이후 시작 시간(초)을 입력해 주세요.");
      // }

      let subtitles = this.subtitles.map((item) => ({
        ...item,
        start: this.timeToSeconds(item.start),
        end: this.timeToSeconds(item.end),
      }));
      // 최송이 작성
      // 강조 자막 체크해서, 영상정보에 필요한 항목들에 값 저장
      let subtitle = subtitles.find((item) => item.isHighlight);
      this.specialRepeatStart = Number(subtitle.start);
      this.specialRepeatEnd = Number(subtitle.end);

      this.hintText = subtitle.en;
      this.hiddenText = subtitle.highlightKo;
      let parts = subtitle.ko.split(subtitle.highlightKo);
      this.frontText = parts[0];
      this.backText = parts[1];

      let data = {
        videoId: this.videoId,
        part: this.part,
        id: this.id,
        maxResults: this.maxResults,
        pageToken: this.pageToken,
        regionCode: this.regionCode,
        thumbnailUrl:
          "https://img.youtube.com/vi/" +
          this.id +
          "/" +
          this.thumbnailNo +
          ".jpg",
        title: this.title,
        level: Number(this.level),
        hintText: this.hintText,
        hiddenText: this.hiddenText,
        frontText: this.frontText,
        backText: this.backText,
        subtitles: subtitles,
        categories: this.categories,
        specialRepeatStart: this.specialRepeatStart,
        specialRepeatEnd: this.specialRepeatEnd,
        startTime: this.timeToSeconds(this.startTime),
        endTime: this.timeToSeconds(this.endTime),
        topic: this.topic,
        // afterRepeatTime: this.afterRepeatTime,
        // subtitles: this.subtitles.map((subtitle) => ({
        //   start: parseInt(subtitle.start),
        //   end: parseInt(subtitle.end),
        //   en: subtitle.en,
        //   ko: subtitle.ko,
        //   highlightEn: subtitle.highlightEn, // ✅ 문자열 그대로 저장
        //   highlightKo: subtitle.highlightKo,
        // })),
      };

      if (this.editMode) {
        updateVideo(data).then((res) => {
          console.log("updateVideo", res);
          if (res.data.status == 200) {
            alert("비디오학습 정보가 반영 되었습니다.");
            this.$router.push("/admin/video/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        createVideo(data).then((res) => {
          console.log("createVideo", res);
          if (res.data.status == 200) {
            alert("비디오학습 등록이 완료 되었습니다.");
            // this.$router.push("/admin/video/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
    quizCheck(idx) {
      this.subtitles = this.subtitles.map((item, index) =>
        index === idx ? item : { ...item, isQuiz: false }
      );
    },
    highlightCheck(idx) {
      this.subtitles = this.subtitles.map((item, index) =>
        index === idx
          ? item
          : {
              ...item,
              isHighlight: false,
              highlightKo: "",
              highlightEn: "",
            }
      );
    },
    timeToSeconds(time) {
      const parts = time.split(":").map(Number);
      if (parts.length === 3) {
        // HH:MM:SS 형식
        const [hours, minutes, seconds] = parts;
        return hours * 3600 + minutes * 60 + seconds;
      } else if (parts.length === 2) {
        // MM:SS 형식
        const [minutes, seconds] = parts;
        return minutes * 60 + seconds;
      } else {
        return NaN; // 올바르지 않은 형식
      }
    },
    secondsToTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    },

    updateInput(field, index, event) {
      this.subtitles[index][field] = event.target.value
        .replace(/[^a-zA-Z0-9가-힣\s]/g, "")
        .slice(0, 20);
    },
  },
};
</script>
<style lang="scss" scoped>
textarea {
  width: 100% !important;
}
.el-select {
  width: 370px !important;
}
.thumbnail {
  width: 100%;
  height: auto;
}
</style>
